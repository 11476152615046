import axios from 'axios'

export default function () {
  return {
    namespaced: true,
    state: () => {
      const state = {}

      Object.keys(window.maps.App_Models_Objects_CrmObjectType.objectTypesMap).forEach(objectType => {
        state[objectType] = {
          loaded: false,
          loading: false,
          data: []
        }
      })

      return state
    },
    mutations: {
      loading (state, { objectType }) {
        state[objectType] = {
          loaded: false,
          loading: true,
          data: []
        }
      },
      loaded (state, { objectType, data }) {
        state[objectType] = {
          loaded: true,
          loading: false,
          data
        }
      }
    },
    actions: {
      loadData ({ commit, state }, { objectType, force = false }) {
        if ((force || !state[objectType].loaded) && !state[objectType].loading) {
          commit('loading', { objectType })
          axios.get(Spark.router('api.crm_custom_property.index', { object_type: objectType }))
            .then(resp => {
              commit('loaded', { objectType, data: resp.data })
            })
        }
      }
    }
  }
}
